import { render, staticRenderFns } from "./LicenseEditor.vue?vue&type=template&id=049a2858&scoped=true&"
import script from "./LicenseEditor.vue?vue&type=script&lang=js&"
export * from "./LicenseEditor.vue?vue&type=script&lang=js&"
import style0 from "./LicenseEditor.vue?vue&type=style&index=0&id=049a2858&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "049a2858",
  null
  
)

export default component.exports