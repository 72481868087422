<template>
  <div class="uploader-wrapper">
    <VueFileAgent
      v-model="fileRecords"
      class="m-2"
      :multiple="true"
    />
    <b-row v-if="fileRecords.length">
      <b-col md="1"></b-col>
      <b-col md="3">
        <div class="text-center">Name</div>
      </b-col>
      <b-col md="2">
        <div class="text-center">State</div>
      </b-col>
      <b-col md="3">
        <div class="text-center">Description</div>
      </b-col>
      <b-col class="mr-2">
        <div class="text-center">Expires</div>
      </b-col>
    </b-row>
    <LicenseFileUploaderRow
      v-for="(file, index) in fileRecords"
      :key="index"
      :file="file"
      :index="index"
      class="pl-2 font-small-3"
      @removeFile="remove"
      @changeData="changeData"
    />
    <b-row class="pr-3 pl-4 pb-2 mt-2">
      <b-col>
        <div class="d-flex justify-content-between">
          <b-button
            type="reset"
            variant="outline-secondary"
            @click="resetForm()"
          >
            Cancel
          </b-button>

          <b-button
            type="submit"
            :variant="fileRecords.length ? 'primary' : 'outline-secondary'"
            class="mr-1"
            @click.prevent="uploadFiles()"
          >
            Upload Files
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BRow} from "bootstrap-vue";
import LicenseFileUploaderRow from "@/views/licenses/LicenseFileUploaderRow";

export default {
  components: {
    LicenseFileUploaderRow,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      fileRecords: [],
      Files: [],
    }
  },
  watch: {
    fileRecords(val) {
      val.map(f => {
        let duplicat = false;
        const item = {
          file: f,
          data: "",
        }
        this.Files.map(i => {
          if (i.file.file.name === f.file.name && !duplicat) {
            duplicat = true
          }
        })
        if (!duplicat) this.Files.push(item);
        return this.Files;
      })
    },
  },
  methods: {
    remove(i) {
      this.Files.splice(i, 1);
      this.fileRecords.splice(i, 1);
    },
    uploadFiles() {
      this.$emit("uploadFiles", this.Files);
    },
    resetForm() {
      this.fileRecords = [];
      this.$emit("cancelUploadFiles");
    },
    changeData(i, data) {
      const item = {
        file: this.Files[i].file,
        data,
      }
      this.Files.splice(i, 1, item)
    }
  },
}
</script>

<style scoped>
.uploader-wrapper {
  position: absolute;
  width: 90%;
  background: white;
  border: 2px solid #ebe9f1;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 4px 1px #ebe9f1;
  z-index: 1;
}
</style>