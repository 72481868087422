<template>
  <div>
    <b-row>
      <b-col>
        <h2>Licenses</h2>
        <div id="component-breadcrumbs">
          <b-breadcrumb
            class="breadcrumb-slash"
            :items="breadcrumbItems"
          />
        </div>
      </b-col>
      <b-col>
        <div class="col-3 center-justified">
          Show expired <toggle-button @change="showExpired = $event.value" />
        </div>
      </b-col>
    </b-row>

    <hr>

    <div class="white-bg-container">
      <b-row>
        <b-col>
          <h1>Licenses - {{ FullName }}</h1>
        </b-col>
      </b-row>
      <b-row
        class="pb-1"
      >
        <b-col
          md="5"
          class="cursor-pointer"
          @click="changeFirstLicenseDate"
        >
          <b-row>
            <b-col
              md="4"
              class="mr-1 text-nowrap d-flex align-items-center"
            >
              <span>First Licensed Date</span>
            </b-col>
            <b-col md="5">
              <b-form-input
                id="firstLicensedDate"
                class="cursor-pointer"
                v-model="firstLicensedDate"
                type="date"
                placeholder=""
                name="firstLicensedDate"
                :disabled="true"
              />
              <div
                class="date-wrapper"
                @click="(e) => changeFirstLicenseDate(e)"
              ></div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="2"
          class="mr-1 text-nowrap d-flex align-items-center"
        >
          <p
            class="cursor-pointer pt-05"
            @click="addFiles"
          >Add Files
            <img
              src="../../assets/images/logo/download.png"
              alt="download"
            >
          </p>
        </b-col>
        <b-col>
          <b-form-input
            id="filterLicenses"
            v-model="FilterLicenses"
            placeholder="Filter Licenses"
            name="filterLicenses"
          />
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="Licenses"
        :search-options="{
          enabled: false
        }"
        :pagination-options="{
          enabled: false,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Expires -->
          <span v-if="props.column.field === 'ExpiresAt'">
            {{ props.row.ExpiresAt | dateFormat }}
          </span>
          <!-- Column: Actions -->
          <span v-else-if="props.column.field === 'Actions'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <span @click="downloadFile(props.row)">{{ props.row.Description }}</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <span @click="showDeleteLicense(props.row.LicenseID)">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <b-modal
      id="modal-change-license-date"
      ok-variant="primary"
      ok-title="Save"
      modal-class="modal-primary"
      centered
      title="Change First Licensed Date"
      @ok="confirmChangeFirstLicensedDate()"
    >
      <b-row>
        <b-col
          md="5"
          class="mr-1 pt-05 text-nowrap d-flex align-items-center"
        >
          <span>First Licensed Date</span>
        </b-col>
        <b-col>
          <b-form-input
            id="firstLicensedDateTemp"
            v-model="FirstLicensedDateTemp"
            type="date"
            placeholder=""
            name="firstLicensedDateTemp"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="modal-delete-license"
      ok-only
      ok-variant="danger"
      ok-title="Delete license"
      modal-class="modal-danger"
      centered
      title="Confirm Delete license"
      @ok="confirmDeleteLicense()"
    >
      <div v-if="deleteLicenseID !== null">
        <h2 class="text-center">
          You are about to delete this license.
        </h2>
      </div>
      <div class="text-center">
        Are you sure you want to do this?
      </div>
    </b-modal>
    <LicenseFileUploader
      v-if="filesUploader"
      @uploadFiles="uploadFiles"
      @cancelUploadFiles="cancelUploadFiles"
    />
  </div>
</template>

<script>
import {BBreadcrumb, BCol, BDropdown, BDropdownItem, BFormInput, BPagination, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import LicenseFileUploader from "@/views/licenses/LicenseFileUploader";
import {mapGetters} from "vuex";
import {VueGoodTable} from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

const apiService = new APIService();

export default {
  components: {
    VueGoodTable,
    BBreadcrumb,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    LicenseFileUploader,
  },
  filters: {
    dateFormat(val) {
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      if (date) return date.toLocaleDateString("en-US", options);
    }
  },
  data() {
    return {
      AllLicenses: [],
      Licenses: [],
      UserID: "",
      deleteLicenseID: null,
      FullName: "",

      firstLicensedDate: "",
      FirstLicensedDateTemp: "",

      FilterLicenses: "",

      filesUploader: false,
      showExpired: false,

      UploadFiles: [],

      sortState: false,
      sortDescription: false,
      sortExpiresAt: false,

      columns: [
        {
          label: "State",
          field: "State",
        },
        {
          label: "Description",
          field: "Description",
        },
        {
          label: "Expires",
          field: "ExpiresAt",
        },
        {
          label: "Actions",
          field: "Actions",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
    breadcrumbItems: function () {
      return [
        {
          text: 'Licenses',
          href: '/licenses'
        },
        {
          text: this.FullName,
        },
      ]
    },
  },
  watch: {
    showExpired(val) {
      const currentDate = new Date().setHours(0, 0, 0, 0);
      this.Licenses = val ? this.AllLicenses : this.AllLicenses.filter(i => new Date(i.ExpiresAt) >= currentDate);
    }
  },
  created() {
    this.loadLicense();
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    loadLicense() {
      this.UserID = this.$route.params.userID;
      const currentDate = new Date().setHours(0, 0, 0, 0);
      if (this.UserID) {
        apiService
            .get("licenses/user/" + this.UserID + "/list")
            .then(res => {
              this.AllLicenses = res.data.Licenses;
              this.firstLicensedDate = res.data.FirstLicensedDate ? res.data.FirstLicensedDate.split('T')[0] : "";
              this.AllLicenses.sort((a, b) => (a.ExpiresAt > b.ExpiresAt) ? 1 : ((b.ExpiresAt > a.ExpiresAt) ? -1 : 0))
              this.Licenses = this.showExpired ? this.AllLicenses : this.AllLicenses.filter(i => new Date(i.ExpiresAt) >= currentDate);
              this.FullName = res.data.UserFullname;
            })
            .catch(err => {
              console.log(err)
            });
      }
    },

    sortTable(name, order) {
      this.Licenses.sort((a, b) => (a[name] > b[name]) ? (order ? 1 : -1) : ((b[name] > a[name]) ? (order ? -1 : 1) : 0))
      return !order
    },

    downloadFile(file) {
      const link = document.createElement('a');
      link.href = '/api/file/stream/' + file.FileAttachmentID;
      document.body.appendChild(link);
      link.click();
    },

    showDeleteLicense(val) {
      this.deleteLicenseID = val;
      this.$bvModal.show('modal-delete-license');
    },

    confirmDeleteLicense() {
      apiService
          .delete("license/" + this.deleteLicenseID)
          .then(res => {
            if (res) {
              this.deleteLicenseID = null;
              this.showToast('success', 'top-center', 4000, 'License deleted!');
              this.loadLicense();
            }
          })
    },

    addFiles() {
      this.filesUploader = true;
    },

    cancelUploadFiles() {
      this.filesUploader = false;
    },

    changeFirstLicenseDate(e) {
      e.preventDefault();
      this.FirstLicensedDateTemp = this.firstLicensedDate;
      this.$bvModal.show('modal-change-license-date');
    },

    confirmChangeFirstLicensedDate() {
      const postData = {
        "userId": this.UserID,
        "firstLicensedDate": this.FirstLicensedDateTemp ? this.FirstLicensedDateTemp : null,
      }
      apiService
          .post("licenses/user/first-licensed-date", postData)
          .then(() => {
            this.firstLicensedDate = this.FirstLicensedDateTemp;
          })
    },

    uploadFiles(data) {
      const fields = [
        {key: 'Description', message: 'Must add a Description.'},
        {key: 'State', message: 'Must add a State.'},
        {key: 'ExpiresAt', message: 'Must add a Date.'},
      ];

      this.UploadFiles = data.map(item => {
        return {
          FileName: item.file.file.name,
          FileSize: item.file.file.size,
          CreatedAt: item.file.file.lastModified,
          View: "",
          file: item.file.file,
          data: item.data,
        };
      })

      if (this.UserID) {
        this.UploadFiles.map(FileData => {
          for (const field of fields) {
            if (!FileData.data[field.key] || FileData.data[field.key].length <= 0) {
              this.showToast('danger', 'top-center', 4000, JSON.stringify(field.message));
              return;
            }
          }

          let formData = new FormData();
          const data = {
            "ParentObjectKey": this.UserID,
            "ParentObjectType": "UserAccount"
          }
          const documentJson = JSON.stringify(data)
          formData.append("FormFile", FileData.file);
          formData.append("Document", documentJson);
          apiService
              .post("file/create", formData, {'Content-Type': 'multipart/form-data'})
              .then(res => {
                this.createLicense(res.data.fileID, FileData.data);
                this.filesUploader = false;
              })
              .catch(function (response) {
                console.log(response);
              });
        })
      } else {
        this.showToast('danger', 'top-center', 4000, JSON.stringify("You don't have user id"));
      }
    },

    createLicense(val, data) {
      const postData = {
        "UserID": this.UserID,
        "FileID": val,
        "Description": data.Description,
        "State": data.State,
        "ExpiresAt": data.ExpiresAt,
      }
      apiService
          .post("license/create", postData)
          .then(res => {
            this.loadLicense();
          })
          .catch(function (response) {
            console.log(response);
          });
    }
  },
}
</script>

<style scoped>
.date-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}
.pt-05 {
  padding-top: 0.5rem;
}
</style>