<template>
  <b-row>
    <b-col
      md="1"
      class="pr-0 cursor-pointer"
      @click="removeFile(index)"
    >
      <img
        src="../../assets/images/logo/redBasket.png"
        alt="basket"
      >
    </b-col>
    <b-col
      md="3"
      class="pl-0"
    >
      {{ file.file.name }}
    </b-col>
    <b-col md="2">
      <b-form-input
        id="state"
        v-model="State"
        :formatter="formatState"
        name="state"
      />
    </b-col>
    <b-col md="3">
      <b-form-input
        id="description"
        v-model="Description"
        name="description"
      />
    </b-col>
    <b-col class="mr-2">
      <b-form-input
        id="expiresAt"
        v-model="ExpiresAt"
        min="1900-01-01"
        max="2099-12-31"
        type="date"
        name="expiresAt"
      />
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BFormInput, BRow} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    file: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      Description: "",
      State: "",
      ExpiresAt: "",
    }
  },
  watch: {
    Description() {
      this.changeData();
    },
    State() {
      this.changeData();
    },
    ExpiresAt() {
      this.changeData();
    },
  },
  methods: {
    removeFile(index) {
      this.$emit("removeFile", index);
    },

    changeData() {
      const data = {
        Description: this.Description,
        State: this.State,
        ExpiresAt: this.ExpiresAt,
      }
      this.$emit("changeData", this.index, data);
    },

    formatState(e){
      return String(e).substring(0,4);
    },
  }
}
</script>

<style scoped>

</style>